<template>
  <div class="w-full">
    <vs-row vs-w="12" vs-align="flex-start" vs-justify="flex-start">
      <vs-col vs-w="6" vs-align="center" vs-justify="center">
        <Agenda
          :blockActions="blockActions"
          @data-selecionada="setDataSelecionada"
        ></Agenda>
      </vs-col>
      <vs-col vs-w="12" vs-align="center" vs-justify="center" class="mt-5">
        <div class="w-full">
          <div
            id="agenda-lembrete"
            class="customize-table vs-con-loading__container"
          >
            <div v-if="lembretes.length == 0">
              <div class="pt-20 scroll-agenda-lembretes con-colors">
                <ul class="pt-20">
                  <li class="danger-box">
                    <h2 class="p-5" style="color: white !important">
                      Nenhum Lembrete
                    </h2>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else class="w-full">
              <div class="w-full" style="background: black">
                <h5 class="text-white p-2">
                  Total de Lembretes: {{ lembretes.length }}
                </h5>
              </div>
              <VuePerfectScrollbar
                class="scroll-agenda-lev"
                :settings="settings"
              >
                <vs-table
                  stripe
                  :data="lembretes"
                  max-items="8"
                  pagination
                  class="m-0 mx-2"
                  noDataText="Nenhum Lembrete!"
                  search
                  :key="reRenderTable"
                >
                  <template slot="header">
                    <vs-row
                      class="my-3"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                      vs-w="12"
                    >
                      <vs-col
                        vs-type="flex"
                        vs-justify="flex-end"
                        vs-align="flex-end"
                        vs-w="12"
                      >
                        <el-select
                          filterable
                          @change="filterStatusLembrete"
                          placeholder="Status do Lembrete"
                          class="mx-5"
                          v-model="statusSelecionado"
                          :popper-append-to-body="false"
                        >
                          <el-option
                            v-for="status in statusLembrete"
                            :value="status.id"
                            :label="status.nome"
                            :key="status.id"
                          ></el-option>
                        </el-select>
                      </vs-col>
                    </vs-row>
                  </template>
                  <template slot="thead">
                    <vs-th sort-key="id_contrato" style="width: 5%" id="center"
                      >Contrato</vs-th
                    >
                    <vs-th style="width: 15%" id="center">Cliente</vs-th>
                    <vs-th style="width: 15%" sort-key="colaborador" id="center"
                      >Data Criação</vs-th
                    >
                    <vs-th style="width: 12%" id="center">Data Marcada</vs-th>
                    <vs-th style="width: 12%" id="center">Assunto</vs-th>
                    <vs-th style="width: 31%" id="center">Observação</vs-th>
                    <vs-th style="width: 15%" sort-key="status" id="center"
                      >Status</vs-th
                    >
                  </template>
                  <template slot-scope="{ data }">
                    <vs-tr
                      :key="index"
                      v-for="(tr, index) in data"
                      :style="
                        tr.status > 0
                          ? 'color: green; background-color: #e8fce8 !important'
                          : ''
                      "
                    >
                      <vs-td style="font-size: 12px" class="text-center">
                        <b
                          :style="
                            tr.id_contrato
                              ? 'color: darkblue; cursor: pointer'
                              : ''
                          "
                          @click="tr.id_contrato ? goToDoc(tr) : ''"
                          >{{ tr.id_contrato ? tr.id_contrato : "..." }}</b
                        >
                      </vs-td>
                      <vs-td style="font-size: 12px" class="text-center">
                        <vs-button
                          color="dark"
                          class="py-0 px-0 my-0 pr-1"
                          size="small"
                          type="line"
                          @click="renderComponentCliente(tr.id_cliente)"
                        >
                          <span class="nome-cliente-small">{{
                            tr.nome_cliente ? tr.nome_cliente : "..."
                          }}</span>
                        </vs-button>
                      </vs-td>
                      <vs-td
                        style="font-size: 12px"
                        class="text-center font-bold"
                      >
                        {{ tr.data_criacao }}
                      </vs-td>
                      <vs-td
                        style="font-size: 12px"
                        class="text-center font-semibold"
                      >
                        {{ tr.data_marcada }}
                      </vs-td>

                      <vs-td
                        style="font-size: 12px"
                        class="text-center font-bold"
                      >
                        {{ tr.assunto }}
                      </vs-td>
                      <vs-td
                        style="font-size: 12px; text-align: justify"
                        class="font-semibold"
                      >
                        {{ tr.obs }}</vs-td
                      >
                      <vs-td>
                        <div class="w-full">
                          <vs-row
                            vs-w="12"
                            vs-type="flex"
                            vs-justify="center"
                            vs-align="center"
                          >
                            <vs-col
                              vs-w="8"
                              vs-type="flex"
                              vs-justify="center"
                              vs-align="center"
                            >
                              <div
                                class="w-full"
                                style="cursor: pointer"
                                @click="markLembreteAsRead(tr)"
                              >
                                <Badge
                                  :text="
                                    tr.status > 0 ? 'Concluído' : 'Pendente'
                                  "
                                  :color="tr.status > 0 ? 'success' : 'danger'"
                                ></Badge>
                              </div>
                            </vs-col>
                          </vs-row>
                        </div>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </VuePerfectScrollbar>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
    <component
      v-bind:is="componentBind"
      :key="reRenderComponent"
      :id_cliente="clienteSelected"
      @update="getLembretes"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js"
import EditarCliente from "./../cliente/editarSemBotao.vue"
import Agenda from "./components/agenda.vue"
export default {
  data () {
    return {
      filtro: {
        data: this.$formartData.dataFiltro()
      },
      lembretes: [],
      originLembretes: [],
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      blockActions: false,
      reRenderTable: 0,
      statusSelecionado: null,
      statusLembrete: [
        { id: null, nome: "Todos" },
        { id: 0, nome: "Não Lido / Pendente" },
        { id: 1, nome: "Lido / Concluído" }
      ],
      clienteSelected: {},
      componentBind: "",
      reRenderComponent: 0
    }
  },
  methods: {
    async setDataSelecionada (data) {
      this.filtro.data = await this.$formartData.formatar(data, 1)
      await this.getLembretes()
    },
    async goToDoc (doc) {
      await this.$vs.loading({
        container: "#agenda-lembrete",
        scale: 0.6,
      })
      try {
        await this.$http.post("markLembreteAsRead", { id: doc.id, status: 1 })
        this.lembreteOpen = false
        location.href = `/contrato?id=${doc.id_contrato}`
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#agenda-lembrete > .con-vs-loading")
      }
    },
    async markLembreteAsRead (doc) {
      await this.$vs.loading({
        container: "#agenda-lembrete",
        scale: 0.6,
      })
      try {
        await this.$http.post("markLembreteAsRead", { id: doc.id, status: !doc.status })
        doc.status = !doc.status
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#agenda-lembrete > .con-vs-loading")
      }
    },
    async renderComponentCliente (id_cliente) {
      this.clienteSelected = id_cliente
      this.componentBind = "EditarCliente"
      this.reRenderComponent++
    },
    async getLembretes () {
      await this.$vs.loading({ container: "#agenda-lembrete", scale: 0.6 })
      this.blockActions = true
      try {
        this.originLembretes = await this.$http.post("getLembretes", this.filtro)
        this.lembretes = Object.assign(this.originLembretes)
        await this.filterStatusLembrete(this.statusSelecionado)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.reRenderTable++
        this.blockActions = false
        await this.$vs.loading.close("#agenda-lembrete > .con-vs-loading")
      }
    },
    async messageError () {
      this.$vs.notify({
        title: "Ação Inválida",
        text: "Este lembrete pertence a outro colaborador!",
        color: "warning"
      })
    },
    async filterStatusLembrete (statusSelect) {
      if (statusSelect != null) {
        this.lembretes = this.originLembretes.filter(
          element => element.status == statusSelect
        )
      } else {
        this.lembretes = Object.assign(this.originLembretes)
      }
      this.reRenderTable += 1
    }
  },
  async mounted () {
    this.filtro.id_colaborador = window.localStorage.getItem("id")
    await this.getLembretes()
  },
  components: {
    ...components,
    Agenda,
    EditarCliente
  }
};
</script>
<style lang="scss">
.scroll-agenda-lembretes {
  height: 73vh !important;
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.customize-table {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
.nome-cliente-small {
  font-weight: 600;
  font-size: 10px;
}
</style>
